import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/welcome'
  },
  {
    path: '/welcome',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/about-us',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUsHistory.vue')
  },
  {
    path: '/about-us/our-history',
    name: 'History',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUsHistory.vue')
  },
  {
    path: '/about-us/our-mission',
    name: 'Mission',
    component: () => import(/* webpackChunkName: "about" */ '../views/Article.vue')
  },
  {
    path: '/school-activities',
    name: 'SchoolLife',
    component: () => import(/* webpackChunkName: "about" */ '../views/Gallery2.vue')
  },
  {
    path: '/school-activities/kindergarten',
    name: 'Kindergarten',
    component: () => import(/* webpackChunkName: "about" */ '../views/Gallery.vue')
  },
  {
    path: '/school-activities/primary',
    name: 'Primary',
    component: () => import(/* webpackChunkName: "about" */ '../views/Gallery.vue')
  },
  {
    path: '/school-activities/secondary',
    name: 'Secondary',
    component: () => import(/* webpackChunkName: "about" */ '../views/Gallery.vue')
  },
  {
    path: '/school-activities/higher-level',
    name: 'HigherLevel',
    component: () => import(/* webpackChunkName: "about" */ '../views/Gallery.vue')
  },
  {
    path: '/school-activities/non-academic',
    name: 'NonAcademic',
    component: () => import(/* webpackChunkName: "about" */ '../views/Gallery.vue')
  },
  {
    path: '/admissions',
    name: 'Admissions',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admissions.vue')
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUs.vue')
  },
  {
    path: '/school-news',
    name: 'SchoolNews',
    component: () => import(/* webpackChunkName: "about" */ '../views/Article2.vue')
  },
  {
    path: '/class-rules',
    name: 'ClassRules',
    component: () => import(/* webpackChunkName: "about" */ '../views/class-rules.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

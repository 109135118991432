<template>
  <v-app>
    <v-app-bar
      fixed
      app
      :color="bg"
    >
      <div class="d-flex align-center ">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo.jpg"
          transition="scale-transition"
          width="40"
        />

        <v-tabs align-with-title background-color="transparent" v-model="tabIdx" @change="tabChange">
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab>
            Welcome
          </v-tab>
          <v-tab>
            About Us
          </v-tab>
          <v-tab>
            School Activities
          </v-tab>
          <!-- <v-tab>
            Admissions
          </v-tab> -->
          <v-tab>
            Contact Us
          </v-tab>
          <!-- <v-tab>
            School News
          </v-tab> -->
        </v-tabs>
      </div>

      <template v-slot:extension v-if="false">
        <!-- <v-tabs align-with-title background-color="transparent" v-model="subIdx1" @change="subChange1" v-if="tabIdx==1">
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab>
            Our History
          </v-tab>
          <v-tab>
            Our Mission
          </v-tab>
        </v-tabs> -->
        <!-- <v-tabs align-with-title background-color="transparent" v-model="subIdx2" @change="subChange2" v-if="tabIdx==2">
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab>
            Kindergarten
          </v-tab>
          <v-tab>
            Primary
          </v-tab>
          <v-tab>
            Secondary
          </v-tab>
          <v-tab>
            Higher Level
          </v-tab>
          <v-tab>
            Non-Academic
          </v-tab>
        </v-tabs> -->
      </template>
    </v-app-bar>

    <v-main>
      <transition name="fade">
        <router-view/>
      </transition>
      <my-footer></my-footer>
    </v-main>
  </v-app>
</template>

<script>
import MyFooter from '@/components/Footer.vue'
export default {
  name: 'App',

  data: () => ({
    //
    bg: 'white',
    tabIdx: 0,
    subIdx1: 0,
    subIdx2: 0,
    routes: [
      'Home',
      'About',
      'SchoolLife',
      'Admissions',
      'ContactUs',
      'SchoolNews'
    ],
    mainRoutes: {
      0: 'Home',
      1: 'About',
      2: 'SchoolLife',
      // 3: 'Admissions',
      3: 'ContactUs',
      4: 'SchoolNews'
    },
    subRoutes1: {
      0: 'History',
      1: 'Mission'
    },
    subRoutes2: {
      0: 'Kindergarten',
      1: 'Primary',
      2: 'Secondary',
      3: 'HigherLevel',
      4: 'NonAcademic'
    }
  }),
  components: {
    MyFooter
  },
  mounted () {
    window.onscroll = () => {
      // this.changeColor()
    }
  },
  methods: {
    changeColor () {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        this.bg = 'white'
      } else {
        this.bg = 'transparent'
      }
    },
    tabChange (idx) {
      if (idx in this.mainRoutes) {
        this.$router.push({ name: this.mainRoutes[idx] })
      } /* else if (idx === 1) {
        this.subIdx1 = 0
        this.$router.push({ name: this.subRoutes1[0] })
      } else if (idx === 2) {
        this.subIdx2 = 0
        this.$router.push({ name: this.subRoutes2[0] })
      } */
    },
    subChange1 (idx) {
      if (idx in this.subRoutes1) {
        this.$router.push({ name: this.subRoutes1[idx] })
      }
    },
    subChange2 (idx) {
      if (idx in this.subRoutes2) {
        this.$router.push({ name: this.subRoutes2[idx] })
      }
    }
  }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  margin-top: -64px;
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  margin-top: -64px;
  opacity: 0;
}</style>

<template>
  <v-footer dark padless>
    <v-card tile class="indigo lighten-1 white--text text-center">
      <v-card-text>
        <v-btn v-for="icon in icons" :key="icon" class="mx-4 white--text" icon>
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0" style="width: 100vw;">
        <div class="d-flex flex-row justify-center mb-6">
          <div class="d-flex flex-column pa-4">
            <div class="pa-2">
              <v-icon height="24">mdi-phone</v-icon>
            </div>
            <div class="pa-2">
              <strong>Phone</strong>
            </div>
            <div class="pa-2">
              +62-21 531 62677
            </div>
            <div class="pa-2">
              +62-813-1496-9650 (WA)
            </div>
            <!-- <div class="pa-2 d-flex flex-row justify-center">
              <v-img
                :lazy-src="require('../assets/dummy_image.png')"
                max-height="75"
                max-width="75"
                :src="require('../assets/dummy_image.png')"
              ></v-img>
            </div> -->
          </div>
          <div class="d-flex flex-column pa-4">
            <div class="pa-2">
              <v-icon>mdi-email</v-icon>
            </div>
            <div class="pa-2">
              <strong>Email</strong>
            </div>
            <div class="pa-2">
              <strong>Inquiries & Admission</strong>
              <br/>
              admin@mail.anderson.sch.id
            </div>
          </div>
          <div class="d-flex flex-column pa-4">
            <div class="pa-2">
              <v-icon>mdi-home</v-icon>
            </div>
            <div class="pa-2">
              <strong>Address</strong>
            </div>
            <div class="pa-2">
              Jl. Samosir
              <br/>
              Nusa Loka, BSD
              <br/>
              Tangerang Selatan
            </div>
          </div>
        </div>
      </v-card-text>

    </v-card>
  </v-footer>
</template>

<script>

export default {
  data: () => ({
    icons: []
  })
}
</script>

<style scoped>

</style>
